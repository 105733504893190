.nav-link-custom {
  position: relative;
  display: inline-block;
  color: #000;
  padding-bottom: 2px;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.nav-link-custom::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #f0ad4e;
  left: 0;
  bottom: 0; 
  transition: width 0.3s ease-in-out;
}

.nav-link-custom:hover,
.nav-link-custom:focus,
.nav-link-custom.active {
  color: #f0ad4e; 
}

.nav-link-custom:hover::after,
.nav-link-custom:focus::after,
.nav-link-custom.active::after {
  width: 100%; 
}
.nav-link-custom {
  color: inherit; 
}

.nav-link-custom .active-link {
  color: #f0ad4e;
}

.dropdown-item.active, .dropdown-item:active{
  background-color: #f0ad4e !important;
}

