.course-card {
    position: relative;
    transition: all 0.3s ease;
  }
  
  .course-card.blurred {
    filter: blur(5px);
    pointer-events: none; /* Prevent interaction */
    opacity: 0.6;
  }
  
  .course-card img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  .blurred {
    filter: blur(1px);
    pointer-events: none; 
    opacity: 0.8;
  }
  
  .card-img-top.blurred-img {
    filter: blur(1px);
    transition: all 0.3s ease;
  }
  .cardsbutton{
    display: flex;
    justify-content: space-between;
  }
  .syllabuscontainer{
    padding: 2rem;
    background-color: #fdf8ee;
  }
  
  .syllabus-input {
    background-color: #f1f5d6;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 100%;
    /* max-width: 400px; */
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .syllabus-input:focus {
    background-color: #f0f3d3;
    outline: none;
    border-color: #007bff;
  }
  
  