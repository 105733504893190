.textContainer{
    display: flex; 
    justify-content: space-between;
  
    align-items: center;
    align-content: center;
  /* flex-direction: row;  */
}
.cardbody{
    background-color: #fdf8ee !important;
}
.cardTitle{
    color: #f0ad4e !important;
    margin-right: 10px;
}
.text{
    text-align: left;
}

@media (max-width: 576px) {
   .mobileInterview{
    width: 150px;
    height: 200px;
    border-radius: 35px;
   }
   .images-style{
    width: 65%;
   }
}
@media (min-width: 992px)  {
    .lapInterview{
        width: 150px;
        height: 200px;
        border-radius: 35px;
    }
}
.downloadbtn{
    margin-top: 52px !important;
    margin-left: 68px !important;
   
    
}
.btnContainer{
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: end;
    align-content: end;
}
.leftContainer{
    display: flex;
    justify-content: start;
    flex-direction: column;
}
.rightContainer{
    display: flex;
    justify-content: end;
}