.background-color{
    background-color:#fdf8ee ;
}
.headContainer{
    text-align: center !important;
    /* border: red 1px solid; */
}
.text-align{
    text-align: center !important;
}
.my-5 {
    margin-top: 3rem !important;
 margin-bottom: 0px !important;
}
.color{
    color: #f0ad4e !important;
}





@media (max-width: 576px) {
    .mobile{
     display: flex;
     flex-direction: column;
    }
    .interview-left-container{
       display: none;
    }
    .main-container{
        width: 100%;
        align-items: center;
         height: 100%;
         padding-bottom: 30px;
     }
     .row>* {   width: none !important ;}
     
.card-styles{
    width: 300px;
}
.card-bottom-share{
    width: 30%;
    
    right: 0%;
    
   
    display: flex;
    text-align: end;
    flex-direction: column;
}
 }
 @media (min-width: 992px)  {
     .lap{
        display: flex;
        
     }
     .interview-right-container {
    
        padding: 1rem;
        overflow-y: auto;
        height: 500px;
        padding: 1rem;
       
    }
    .interview-left-container {
   
        top: 250px;
        left: 0;
        height: 500px;
        width: 20%;
        background-color:#fdf8ee ;
       
        z-index: 10;
        padding: 1rem;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        margin: 1rem;
        height: 450px;
        overflow-y: auto;
        border-radius: 10px;
    }
    .main-container{
        width: 100%;
       
         height: 500px;
         padding-bottom: 30px;
     }
     .card-bottom-share{
        width: 20%;
        
        right: 0%;
        
       
        display: flex;
        text-align: end;
        flex-direction: column;
    }
     
.card-styles{
    width: 700px;
    height: auto;
    border: red solid 1px;  
}
.comapanynames{
    background-color: white;
    border-bottom: 2px solid #f0ad4e !important;;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    margin: 10px;
    
}
 }
txt-color{
    color: black !important;
}
.card-bottom-container {
   
  
   display: flex;
   justify-content: flex-end;
   
  position: relative;
  right: 0;
   
   width: 100%;
}

.share-button{
    border: 0px;
    border-radius: 20px;
    background-color:#e5edef;
}
.share-button:hover {
    background-color: #e4c248;
}
